import { Button, Card, Col, Form, Row, theme } from 'antd';
import Input from 'antd/es/input';
import React, { useMemo } from 'react';
import {
  UserOutlined,
  LockOutlined,
  GoogleOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { LogoType } from 'assets/svg';
import { authApi } from 'store/services';
import { TSignIn } from 'types/auth';
import { getBrowserData } from 'utils/browserData';

export const Login: React.FC = () => {
  const [login, { isLoading, data, isSuccess, reset }] =
    authApi.useLoginMutation();
  const [fields] = Form.useForm<TSignIn>();
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const requireGoogle = useMemo(() => {
    return isSuccess && data && 'google_auth' in data;
  }, [isSuccess, data]);

  const resetData = () => {
    fields.resetFields();
    reset();
  };

  const onFinish = async (values: TSignIn) => {
    const browserData = await getBrowserData();
    debugger
    await login({ ...values, browserData });
  };

  return (
    <Col>
      <Card title={<LogoType fill={{ primary: colorPrimary }} />}>
        <Form name="normal_login" form={fields} onFinish={onFinish}>
          <Form.Item
            name="login"
            rules={[{ required: true, message: 'Please input your Login!' }]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Login"
              disabled={requireGoogle}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
              disabled={requireGoogle}
            />
          </Form.Item>
          <Form.Item
            hidden={!requireGoogle}
            name="code"
            rules={[
              {
                required: requireGoogle,
                message: 'Please input your otp code!',
              },
              { max: 6, min: 6, message: 'Code must contain 6 characters!' },
            ]}
          >
            <Input prefix={<GoogleOutlined />} placeholder="Otp code" />
          </Form.Item>
          <Form.Item>
            <Row gutter={10}>
              <Col flex={'none'} hidden={!requireGoogle}>
                <Button
                  block
                  type="default"
                  className="login-form-button"
                  disabled={isLoading}
                  hidden={!requireGoogle}
                  onClick={resetData}
                >
                  <CloseOutlined />
                </Button>
              </Col>
              <Col flex={'auto'}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={isLoading}
                >
                  Log in
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </Col>
  );
};
