import blockchainIconByName from 'assets/svg/blockchains';
import { BlockchainEnum, FiatCurrency, UserBalance } from 'types/user-balance';
import { TetherLogo } from '../../../../../assets/svg/blockchains/TetherLogo';
import React from 'react';
import { MaticLogo } from '../../../../../assets/svg/blockchains/MaticLogo';
import KZTLogo from '../../../../../assets/svg/fiat/KZTLogo';
import UAHLogo from '../../../../../assets/svg/fiat/UAHLogo';
import RUBLogo from '../../../../../assets/svg/fiat/RUBLogo';
import { USDCLogo } from '../../../../../assets/svg/blockchains/USDCLogo';

export type TBalanceListItem = {
  key: string;
  label: string;
  logo: JSX.Element;
  amount: number;
  balanceId: string;
  fiatCurrency?: FiatCurrency
};

export const balanceConfig = (data: UserBalance[]): TBalanceListItem[] => {
  const bl: TBalanceListItem[] = [];

  for (const key in BlockchainEnum) {
    if (key === BlockchainEnum.FIAT) continue;
    bl.push({
      key: `user_balance_${key}`,
      label:
        key === 'tether'
          ? 'TETHER'
          : key === 'MATIC.POLY'
          ? 'MATIC'
          : key === 'BNB.BSC'
          ? 'BNB.BEP2'
          : key === 'USDC'
          ? 'USDC'
          : key,
      logo:
        key === 'tether' ? (
          <TetherLogo width={25} height={25} />
        ) : key === 'MATIC.POLY' ? (
          <MaticLogo width={30} height={25} />
        ) : key === 'USDC' ? <USDCLogo width={25} height={25}/>
        : (
          blockchainIconByName(key as BlockchainEnum)
        ),
      amount: 0,
      balanceId: '',
    });

    for (let step = 0; step < data.length; step += 1) {
      if (data[step].blockchain === key) {
        bl[bl.length - 1].amount = data[step].amount;
        bl[bl.length - 1].balanceId = data[step].id;
      }
    }
  }

  // FIAT
  for (const key in FiatCurrency) {
    const balance = data.filter((balance) => balance.currency === key)[0];
    let logo;
    switch (key) {
      case 'KZT':
        logo = <KZTLogo />;
        break
      case 'UAH':
        logo = <UAHLogo />;
        break
      case 'RUB':
        logo = <RUBLogo />;
        break
    }
    bl.push({
      key: `user_balance_FIAT_${key}`,
      label: key,
      //@ts-ignore
      logo: logo,
      amount: balance?.amount ?? 0,
      balanceId: balance?.id ?? 0,
      fiatCurrency: key as FiatCurrency
    });
  }

  return bl;
};
