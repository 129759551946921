import React, {useMemo, useState} from 'react';
import { Modal } from 'antd';
import { CustomTable } from 'components/Table';
import {statisticApi} from 'store/services';
import { columns } from 'pages/Users/utils/tableConfig';
import {convertDates, EnumDateTypes} from "../../../pages/Dashboard/utils/dates";
import moment, {Moment} from "moment/moment";
import {RangeValue} from "../../../pages/Dashboard/Home";

interface Props {
  open: boolean;
  closeHandler: (onOk: boolean) => void;
}

export const ActiveUsersModal: React.FC<Props> = ({ open, closeHandler }) => {
  const [currentDate] = useState<Moment>(moment());
  const [type] = useState<EnumDateTypes>(EnumDateTypes.date);
  const [currentDates] = useState<RangeValue<Moment>>([
    moment().subtract(1, 'M'),
    moment(),
  ]);

  const dates = useMemo(
      () => convertDates(currentDates, currentDate, type),
      [currentDates, currentDate, type]
  );

  const { data, isLoading } = statisticApi.useGetStatisticQuery({
  ...{ query: 'users'},
    ...dates,
  });

  const data_user = data?.statistic?.openedSessions.sessions || [];
  const d_user = data_user.map((el: any) => ({
    ...el.user,
    id: el.userId,
  }));

  return (
      <Modal
          title={'Active Users'}
          open={open}
          onCancel={() => closeHandler(false)}
          footer={null}
          className="userDateModalStyle"
      >
        <CustomTable
            loading={isLoading}
            columns={columns(false, true)}
            data={d_user}
            currentPage={1}
            setCurrentPage={() => {}}
            hideSizeChanger
        />
      </Modal>
  );
};