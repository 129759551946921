import { BrowserData } from '../types/auth';

export async function getBrowserData() {
  const browserData = {
    browser: {},
    cpu: {},
    device: {},
    engine: {},
    os: {},
    ua: '',
    resolution: '',
    timezone: '',
    systemLanguage: '',
    canvasPrint: '',
  } as BrowserData;

  if (typeof window !== 'undefined') {
    const module = await import('clientjs'); // Ждем, пока модуль будет загружен
    const client = module.ClientJS;
    const clientJ = new client();

    //@ts-ignore
    const browserDataActual = clientJ.getBrowserData();
    browserData.browser = browserDataActual.browser;
    browserData.cpu = {
      ...browserDataActual.cpu,
      logicalProcessor: navigator.hardwareConcurrency,
    };
    browserData.device = {
      model: browserDataActual.device?.model || undefined,
      type: browserDataActual.device?.type || undefined,
      vendor: browserDataActual.device?.vendor || undefined,
    };
    browserData.engine = browserDataActual.engine;
    browserData.os = browserDataActual.os;
    browserData.ua = browserDataActual.ua;
    browserData.resolution = clientJ.getCurrentResolution();
    browserData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    browserData.systemLanguage = Intl.DateTimeFormat().resolvedOptions().locale;
    browserData.canvasPrint = clientJ.getCanvasPrint();
  }

  return browserData; // Убедитесь, что возвращаете результат после всех await
}
