import React from 'react';
import {
  TeamOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EuroCircleOutlined,
  FallOutlined,
  RiseOutlined,
  FireOutlined,
} from '@ant-design/icons';
import { IStatisticData } from 'types/statistic';

export type TStatisticCardItem = {
  title: string;
  dataIndex: keyof IStatisticData;
  prefix: React.ReactNode;
  hideDiff?: boolean | undefined;
};

export const gamesCards: TStatisticCardItem[] = [
  {
    title: 'Max bet',
    dataIndex: 'gamesMaxBet',
    prefix: <FireOutlined />,
  },
  {
    title: 'Max lose',
    dataIndex: 'gamesMaxLose',
    prefix: <FallOutlined />,
  },
  {
    title: 'Max win',
    dataIndex: 'gamesMaxWin',
    prefix: <RiseOutlined />,
  },
];

export const txCards: TStatisticCardItem[] = [
  {
    title: 'All deposits in fiat',
    dataIndex: 'depositsFiatSum',
    prefix: <EuroCircleOutlined />,
  },
  {
    title: 'Tickets buyed',
    dataIndex: 'ticketsBuyed',
    prefix: <ArrowUpOutlined />,
  },
  {
    title: 'Tickets spent',
    dataIndex: 'ticketsSpent',
    prefix: <ArrowDownOutlined />,
  },
];

export const users: TStatisticCardItem[] = [
  {
    title: 'Total Users',
    dataIndex: 'totalUsersWithoutRange',
    prefix: <TeamOutlined />,
    hideDiff: true,
  },
  {
    title: 'New users',
    dataIndex: 'totalUsers',
    prefix: <TeamOutlined />,
  },
  {
    title: 'Opened sessions',
    dataIndex: 'openedSessions',
    prefix: <TeamOutlined />,
  },
];
