import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { ICardConfig } from 'types/grid';
import { TSession } from 'types/session';

export const lastSession: ICardConfig<TSession> = {
  key: 'last-visit',
  title: 'Last visit',
  className: 'user-info-card',
  grids: [
    {
      title: 'Last update',
      dataIndex: 'updatedAt',
      render: (record) => moment(record.updatedAt).fromNow(),
    },
    {
      title: 'Geo',
      dataIndex: 'geo',
      render: (record) => record.geo,
    },
    {
      title: 'User Agent',
      dataIndex: 'browser',
      render: (record) => record.browser,
    },
    {
      title: 'Logged in at',
      dataIndex: 'createdAt',
      render: (record) => moment(record.createdAt).fromNow(),
    },
    {
      title: 'Last visit ip',
      dataIndex: 'ip',
      render: (record) => record.ip,
    },
    {
      title: 'Session type',
      dataIndex: 'admin',
      render: (record) => (
        <Typography.Text code>
          {record.admin ? 'admin-panel' : 'player-panel'}
        </Typography.Text>
      ),
    },
    {
      title: 'Browser',
      dataIndex: 'browserData',
      render: (record) => {
        let browserData = '';
        if (record?.browserData?.browser?.name) {
          browserData += `${record.browserData.browser.name} `;
        }
        if (record?.browserData?.browser?.version) {
          browserData += `${record.browserData.browser.version} `;
        }
        if (record?.browserData?.browser?.major) {
          browserData += `${record.browserData.browser.major}`;
        }
        if (browserData) {
          return browserData;
        }
        return '-';
      },
    },
    {
      title: 'CPU',
      dataIndex: 'browserData',
      render: (record) => {
        let cpu = ``;
        if (record?.browserData?.cpu?.architecture) {
          cpu += `Arch: ${record.browserData.cpu.architecture} `;
        }
        if (record?.browserData?.cpu?.logicalProcessor) {
          cpu += `logical processor: ${record.browserData.cpu.logicalProcessor}`;
        }
        if (cpu) {
          return cpu;
        }
        return `-`;
      },
    },
    {
      title: 'Device',
      dataIndex: 'browserData',
      render: (record) => {
        let device = ``;
        if (record?.browserData?.device?.model) {
          device += `${record.browserData.device.model} `;
        }
        if (record?.browserData?.device?.type) {
          device += `${record.browserData.device.model} `;
        }
        if (record?.browserData?.device?.vendor) {
          device += `${record.browserData.device.vendor}`;
        }
        if (device) {
          return device;
        }
        return '-';
      },
    },
    {
      title: 'Engine browser',
      dataIndex: 'browserData',
      render: (record) => {
        let engineBrowser = ``;
        if (record?.browserData?.engine?.name) {
          engineBrowser += `${record.browserData.engine.name} `;
        }
        if (record?.browserData?.engine?.version) {
          engineBrowser += `${record.browserData.engine.version}`;
        }
        if (engineBrowser) {
          return engineBrowser;
        }
        return `-`;
      },
    },
    {
      title: 'OS',
      dataIndex: 'browserData',
      render: (record) => {
        let os = ``;
        if (record?.browserData?.os?.name) {
          os += `${record.browserData.os.name} `;
        }
        if (record?.browserData?.os?.version) {
          os += `${record.browserData.os.version}`;
        }
        if (os) {
          return os;
        }
        return `-`;
      },
    },
    {
      title: 'Resolution',
      dataIndex: 'browserData',
      render: (record) => {
        if (record?.browserData?.resolution) {
          return record.browserData.resolution;
        }
        return '-';
      },
    },
    {
      title: 'Timezone',
      dataIndex: 'browserData',
      render: (record) => {
        if (record?.browserData?.timezone) {
          return record.browserData.timezone
        }
        return '-'
      },
    },
    {
      title: 'System language',
      dataIndex: 'browserData',
      render: (record) => {
        if (record?.browserData?.systemLanguage) {
          return record.browserData.systemLanguage;
        }
        return '-'
      }
    },
    {
      title: 'Canvas print',
      dataIndex: 'browserData',
      render: (record) => {
        if (record?.browserData?.canvasPrint) {
          return record.browserData.canvasPrint
        }
        return '-'
      },
    },
  ],
};
