import React, { useState } from 'react';
import { columns } from '../utils/tableConfig';
import { CustomTable } from 'components/Table';
import { articlesApi } from 'store/services/articles';

export const ArticlesHome = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(15);

  const { data, isLoading } = articlesApi.useGetAllArticlesQuery({
    fullArticles: false,
  });

  return (
    <CustomTable
      loading={isLoading}
      columns={columns}
      data={data?.articles}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setPerPage={setPerPage}
    />
  );
};
