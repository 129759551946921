import React, { FC } from 'react';
import {
  Modal,
} from 'antd';
import { Flex } from 'antd';
import { TSession } from '../../../types/session';

type Props<T = TSession> = {
  closeHandler: (value: boolean) => void;
  open: boolean;
  data: {} extends T ? {} : T;
};

type SessionItemProps = {
  title: string;
  value: string;
};

const SessionItem: FC<SessionItemProps> = ({ title, value }) => {
  return (
    <div style={{ border: '1px solid black', width: '100%' }}>
      <h4 style={{ textAlign: 'left', fontWeight: '900' }}>{title}</h4>
      <p
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        {value}
      </p>
    </div>
  );
};

export const UserSessionModal: FC<Props> = ({ closeHandler, open, data }) => {
  const onOk = () => closeHandler(true);
  if (data.browserData === null || data.browserData === undefined) return null;
  const onCancel = () => closeHandler(false);
  const { browserData } = data;

  return (
    <Modal
      title={'User sessions'}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      width={'50%'}
    >
      <Flex
        style={{
          gap: '10px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
        align="flex-start"
        justify="center"
        vertical={true}
      >
        <SessionItem title="Browser" value={`${data.browser}`} />
        <SessionItem
          title="Canvas print"
          value={`${browserData.canvasPrint || 'None'}`}
        />
        <SessionItem
          title="CPU"
          value={`architecture: ${browserData.cpu.architecture}, logicalProcessor: ${browserData.cpu.logicalProcessor}`}
        />
        <SessionItem
          title="Engine"
          value={`name: ${browserData.engine.name}, version: ${browserData.engine.version}`}
        />
        <SessionItem
          title="About device"
          value={`name: ${browserData.engine.name}, 
          version: ${browserData.engine.version}, o
          s: ${browserData.os.name}, version: ${browserData.os.version}, 
          resolution: ${browserData.resolution}, lang: ${browserData.systemLanguage}, timeZone: ${browserData.timezone}`}
        />
      </Flex>
    </Modal>
  );
};
